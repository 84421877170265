
if (document.querySelector(".hero__swiper.swiper")) {
    const swiper = new Swiper(".hero__swiper.swiper", {
        freeMode: true,
        pagination: {
            el: ".hero .swiper-pagination",
            clickable: true,
        },
        loop:true,

        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
    });
}


if (document.querySelector(".hero3__swiper.swiper")) {
    const swiper = new Swiper(".hero3__swiper.swiper", {
        freeMode: true,
        pagination: {
            el: ".hero3 .swiper-pagination",
            clickable: true,
            type: "fraction",
        },

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        loop:true,

        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
    });
}


